"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LineItem = exports.QtyPicker = void 0;
/** @jsx jsx */
const theme_ui_1 = require("theme-ui");
const react_1 = require("react");
const SingleProduct_1 = require("../SingleProduct/SingleProduct");
const icons_1 = require("../../assets/icons");
const siteContext_1 = require("../../context/siteContext");
const theme_ui_2 = require("theme-ui");
/** @jsx jsx */
var QtyPicker = function QtyPicker(_ref) {
    var qty = _ref.qty, updater = _ref.updater;
    return theme_ui_1.jsx(theme_ui_2.Flex, {
        sx: {
            border: "1px solid",
            borderColor: "primary",
            width: 120,
            height: 35,
            alignItems: "center",
            borderRadius: 10
        }
    }, theme_ui_1.jsx(theme_ui_2.Button, {
        variant: "counter",
        onClick: function onClick() {
            return qty === 1 ? null : updater(qty - 1);
        }
    }, "-"), theme_ui_1.jsx("input", {
        sx: {
            flex: "1",
            background: "none",
            border: "none",
            borderLeft: "1px solid",
            borderRight: "1px solid",
            color: "primary",
            textAlign: "center",
            appearance: "none",
            "-webkit-appearance": "none",
            "-moz-appearance": "textfield",
            minWidth: 0,
            alignSelf: "stretch",
            borderColor: "primary",
            borderRadius: "0"
        },
        type: "number",
        pattern: "[0-9]*",
        value: qty,
        onChange: function onChange(e) {
            return updater(parseInt(e.currentTarget.value, 10));
        },
        name: "quantity",
        min: "1"
    }), theme_ui_1.jsx(theme_ui_2.Button, {
        variant: "counter",
        onClick: function onClick() {
            return updater(qty + 1);
        }
    }, "+"));
};
exports.QtyPicker = QtyPicker;
var LineItem = function LineItem(props) {
    var id = props.id, title = props.title, quantity = props.quantity, variant = props.variant;
    var _useStore = siteContext_1.useStore(), isMobile = _useStore.isMobile;
    var update = siteContext_1.updateItemsFromCart();
    var price = variant.price, compareAtPrice = variant.compareAtPrice, image = variant.image;
    var _useState = react_1.useState(quantity), stateQuantity = _useState[0], setQuantity = _useState[1];
    var removeFromCart = siteContext_1.removeItemFromCart();
    var updateQuantity = function updateQuantity(qty) {
        update({
            id: id,
            quantity: qty
        });
        setQuantity(qty);
    };
    var itemImage = image ? siteContext_1.client.image.helpers.imageForSize(image, {
        maxWidth: 800,
        maxHeight: 800
    }) : null;
    var isVariant = variant.title !== "Default Title";
    return theme_ui_1.jsx(theme_ui_2.Grid, {
        gap: 4,
        sx: {
            gridTemplateColumns: isMobile ? "125px 1fr" : "200px 1fr"
        },
        pb: 4
    }, itemImage ? theme_ui_1.jsx(theme_ui_2.Box, {
        sx: {
            position: "relative"
        }
    }, theme_ui_1.jsx("img", {
        sx: {
            border: "1px solid",
            borderColor: "primary",
            borderRadius: 4
        },
        src: itemImage,
        alt: title
    }), theme_ui_1.jsx(theme_ui_2.Button, {
        type: "reset",
        variant: "unstyled",
        sx: {
            width: 20,
            p: 0,
            position: "absolute",
            right: 10,
            top: 10
        },
        onClick: function onClick() {
            return removeFromCart(id);
        }
    }, theme_ui_1.jsx(icons_1.Close, {
        width: "20"
    }))) : null, theme_ui_1.jsx(theme_ui_2.Flex, {
        sx: {
            justifyContent: "space-between",
            alignItems: "flex-start",
            maxWidth: "600px",
            alignSelf: "flex-start",
            flexDirection: ["column", "column", "row"]
        }
    }, theme_ui_1.jsx("div", null, theme_ui_1.jsx(theme_ui_2.Heading, {
        sx: {
            fontSize: 20,
            mb: "2"
        }
    }, title), isVariant ? theme_ui_1.jsx(theme_ui_2.Text, {
        as: "p",
        variant: "lineItemVariant",
        mb: "2"
    }, variant.title) : null, theme_ui_1.jsx(theme_ui_2.Text, {
        as: "p",
        variant: "lineItemVariant",
        mt: "2",
        mb: "2"
    }, SingleProduct_1.getPrice({
        compareAtPrice: compareAtPrice,
        price: price
    }))), theme_ui_1.jsx("div", null, theme_ui_1.jsx(exports.QtyPicker, {
        qty: stateQuantity,
        updater: updateQuantity
    }), theme_ui_1.jsx(theme_ui_2.Button, {
        sx: {
            padding: 0,
            textDecoration: "underline",
            pt: 2
        },
        variant: "unstyled",
        onClick: function onClick() {
            return removeFromCart(id);
        }
    }, "Remove Item From Cart")), theme_ui_1.jsx(theme_ui_2.Text, {
        sx: {
            display: ["none", "none", "initial"]
        },
        as: "p",
        variant: "lineItemVariant",
        mt: "2"
    }, "$", price * quantity)));
};
exports.LineItem = LineItem;
