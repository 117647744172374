"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const icons_1 = require("../../assets/icons");
const scrollTo_1 = require("../../utils/scrollTo");
const router_1 = require("@reach/router");
const theme_ui_1 = require("theme-ui");
const siteContext_1 = require("../../context/siteContext");
const framer_motion_1 = require("framer-motion");
/** @jsx jsx */
const theme_ui_2 = require("theme-ui");
var Path = function Path(props) {
    return theme_ui_2.jsx(framer_motion_1.motion.path, Object.assign({
        fill: "transparent",
        strokeWidth: "2",
        stroke: "currentColor"
    }, props));
};
var MenuToggle = function MenuToggle() {
    return theme_ui_2.jsx("svg", {
        width: "23",
        height: "23",
        viewBox: "0 0 23 23"
    }, theme_ui_2.jsx(Path, {
        variants: {
            closed: {
                d: "M 2 2.5 L 20 2.5"
            },
            open: {
                d: "M 3 16.5 L 17 2.5"
            }
        }
    }), theme_ui_2.jsx(Path, {
        d: "M 2 9.423 L 20 9.423",
        variants: {
            closed: {
                opacity: 1
            },
            open: {
                opacity: 0
            }
        },
        transition: {
            duration: 0.1
        }
    }), theme_ui_2.jsx(Path, {
        variants: {
            closed: {
                d: "M 2 16.346 L 20 16.346"
            },
            open: {
                d: "M 3 2.5 L 17 16.346"
            }
        }
    }));
};
var MobileMenu = function MobileMenu(_ref) {
    var isVisible = _ref.isVisible;
    var _useDispatch = siteContext_1.useDispatch(), dispatch = _useDispatch.dispatch, ACTIONS = _useDispatch.ACTIONS;
    var _useLocation = router_1.useLocation(), pathname = _useLocation.pathname;
    var _useStore = siteContext_1.useStore(), windowHeight = _useStore.windowHeight;
    var scroll = function scroll(id) {
        dispatch({
            type: ACTIONS.TOGGLE_NAV
        });
        dispatch({
            type: ACTIONS.TOGGLE_SIDEBAR,
            payload: {
                state: "CLOSED"
            }
        });
        scrollTo_1.scroll(id, pathname);
    };
    var mobileLinks = [{
            action: function action() {
                dispatch({
                    type: ACTIONS.TOGGLE_NAV
                });
                dispatch({
                    type: ACTIONS.SIDEBAR_STATE,
                    payload: "DEFAULT"
                });
                dispatch({
                    type: ACTIONS.TOGGLE_SIDEBAR,
                    payload: {
                        state: "OPEN"
                    }
                });
            },
            label: "Shop"
        }, {
            action: function action() {
                scroll("#about");
            },
            label: "About"
        }, {
            action: function action() {
                scroll("#faq");
            },
            label: "FAQ"
        }, {
            action: function action() {
                scroll("#contact");
            },
            label: "Contact"
        }, {
            action: function action() {
                scroll("#instagram");
            },
            label: "Instagram"
        }];
    return theme_ui_2.jsx(framer_motion_1.AnimatePresence, {
        initial: false
    }, isVisible && theme_ui_2.jsx(framer_motion_1.motion.div, {
        initial: {
            height: 0
        },
        animate: {
            height: windowHeight
        },
        exit: {
            opacity: 0
        },
        sx: {
            display: "flex",
            position: "fixed",
            top: 75,
            left: 0,
            width: "100vw",
            zIndex: 9999,
            backgroundColor: "background",
            flexDirection: "column",
            pt: 6,
            pb: 6,
            justifyContent: "space-around"
        }
    }, mobileLinks.map(function (_ref2) {
        var action = _ref2.action, label = _ref2.label;
        return theme_ui_2.jsx(theme_ui_1.Button, {
            variant: "mobileNav",
            key: label,
            onClick: function onClick() {
                action();
            }
        }, label);
    })));
};
var MobileNav = function MobileNav() {
    var _useDispatch2 = siteContext_1.useDispatch(), dispatch = _useDispatch2.dispatch, ACTIONS = _useDispatch2.ACTIONS;
    var _useStore2 = siteContext_1.useStore(), sideBarState = _useStore2.sideBarState, isNavOpen = _useStore2.isNavOpen, isSidebarOpen = _useStore2.isSidebarOpen;
    var cartCount = siteContext_1.useCartCount();
    var toggleCart = function toggleCart() {
        if (isSidebarOpen && sideBarState === "CART")
            return null;
        dispatch({
            type: ACTIONS.TOGGLE_SIDEBAR,
            payload: {
                state: "OPEN"
            }
        });
        dispatch({
            type: ACTIONS.CLEAR_PRODUCT
        });
        dispatch({
            type: ACTIONS.SIDEBAR_STATE,
            payload: "CART"
        });
    };
    return theme_ui_2.jsx(framer_motion_1.motion.nav, {
        initial: false,
        animate: isNavOpen ? "open" : "closed"
    }, theme_ui_2.jsx(theme_ui_1.Grid, {
        sx: {
            position: "fixed",
            top: "0",
            left: "0",
            width: "100vw",
            height: 75,
            borderBottom: "1px solid",
            borderColor: "primary",
            backgroundColor: "background",
            zIndex: 10,
            pl: "4",
            pr: "4",
            gridTemplateColumns: "1fr auto 1fr"
        }
    }, theme_ui_2.jsx(theme_ui_1.Button, {
        onClick: function onClick() {
            return dispatch({
                type: ACTIONS.TOGGLE_NAV
            });
        },
        variant: "back"
    }, theme_ui_2.jsx(MenuToggle, null)), theme_ui_2.jsx(theme_ui_1.Button, {
        variant: "smile",
        onClick: function onClick() {
            dispatch({
                type: ACTIONS.TOGGLE_NAV,
                payload: "OPEN"
            });
            dispatch({
                type: ACTIONS.TOGGLE_SIDEBAR,
                payload: {
                    state: "close",
                    homeUrl: true
                }
            });
        }
    }, theme_ui_2.jsx(icons_1.LogoSmile, {
        width: "45"
    })), theme_ui_2.jsx(theme_ui_1.Button, {
        sx: {
            textAlign: "right"
        },
        variant: "unstyled",
        onClick: function onClick() {
            return toggleCart();
        }
    }, "Cart (", cartCount, ")")), theme_ui_2.jsx(MobileMenu, {
        isVisible: isNavOpen
    }));
};
exports.default = MobileNav;
