"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// // See other presets to try at https://theme-ui.com/packages/presets
var fontFamily = "\"\u30D2\u30E9\u30AE\u30CE\u89D2\u30B4 Pro W3\", \"Hiragino Kaku Gothic Pro\", Osaka, \u30E1\u30A4\u30EA\u30AA, Meiryo, \"\uFF2D\uFF33 \uFF30\u30B4\u30B7\u30C3\u30AF\", \"MS PGothic\", \"\uFF2D\uFF33 \u30B4\u30B7\u30C3\u30AF\" , \"MS Gothic\", \"Noto Sans CJK JP\", TakaoPGothic, Gothic A1,  -apple-system, BlinkMacSystemFont, \"Segoe UI\",\n Helvetica, Arial, sans-serif,\n \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\";";
var navStyles = {
    color: "inherit",
    textDecoration: "none",
    background: "none",
    cursor: "pointer"
};
var floating = {
    color: "primary",
    textDecoration: "none",
    background: "none",
    cursor: "pointer",
    fontSize: 20,
    zIndex: 100,
    position: "absolute"
};
var mobileNav = {
    color: "inherit",
    textDecoration: "none",
    background: "none",
    cursor: "pointer",
    fontSize: 32,
    fontWeight: "lighter",
    mt: 0
};
var faqStyles = {
    color: "#0047FF",
    textDecoration: "none",
    cursor: "pointer",
    background: "none",
    textAlign: "left",
    borderBottom: "1px solid #0047FF",
    mb: "4",
    pb: "4"
};
var backBtnStyles = {
    color: "#0047FF",
    textDecoration: "none",
    background: "none",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: 0
};
var productStyles = {
    background: "primary",
    display: "block",
    padding: "20px 40px",
    mt: "4",
    cursor: "pointer",
    borderRadius: 10,
    width: "100%",
    maxWidth: "400px"
};
var mobileProduct = {
    background: "primary",
    padding: "20px 40px",
    cursor: "pointer",
    width: "100vw",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    fontSize: 20
};
var unstyled = {
    background: "none",
    color: "inherit",
    padding: "3",
    cursor: "pointer",
    width: "100%",
    textAlign: "left"
};
var checkout = {
    background: "primary",
    color: "background",
    padding: "3",
    cursor: "pointer",
    width: "100%",
    textAlign: "left",
    borderRadius: 0
};
var smile = {
    background: "none",
    padding: "0",
    cursor: "pointer"
};
var counterStyles = {
    background: "none",
    border: "none",
    cursor: "pointer",
    color: "primary",
    padding: "2"
};
var footerSub = {
    background: "\"primary\"",
    borderRadius: "0",
    cursor: "pointer",
    color: "background"
};
exports.default = {
    colors: {
        text: "#0047FF",
        background: "#FFFDF6",
        primary: "#0047FF",
        secondary: "#05a",
        accent: "#609",
        muted: "#f6f6f6",
        primaryOpaque: "rgba(0, 71, 255, 0.6)"
    },
    fonts: {
        body: fontFamily,
        heading: fontFamily,
        monospace: "Menlo, monospace"
    },
    fontWeights: {
        body: 400,
        heading: 700,
        bold: 700
    },
    lineHeights: {
        body: 1.5,
        heading: 1.125
    },
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    offsets: {
        menuWidth: 70
    },
    links: {
        nav: navStyles
    },
    buttons: {
        nav: navStyles,
        faq: faqStyles,
        back: backBtnStyles,
        product: productStyles,
        unstyled: unstyled,
        counter: counterStyles,
        footerSub: footerSub,
        smile: smile,
        mobileNav: mobileNav,
        checkout: checkout,
        floating: floating,
        mobileProduct: mobileProduct
    },
    text: {
        default: {
            fontSize: 20,
            lineHeight: "30px"
        },
        heading: {
            fontWeight: "body",
            mb: "4"
        },
        productTitle: {
            fontWeight: "body",
            mb: "2",
            fontSize: 26
        },
        h1: {
            fontSize: ["22px", "24px", "36px"],
            lineHeight: ["32px", "36px", "54px"],
            fontWeight: "body",
            mb: "3"
        }
    },
    styles: {
        root: {
            background: "#FFFDF6",
            color: "#0047FF",
            fontFamily: fontFamily,
            overflowX: "hidden",
            img: {
                maxWidth: "100%"
            },
            "input::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0
            },
            "input::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0
            }
        }
    }
};
