"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/** @jsx jsx */
const core_1 = require("@emotion/core");
const icons_1 = require("../../assets/icons");
const theme_ui_1 = require("theme-ui");
const router_1 = require("@reach/router");
const scrollTo_1 = require("../../utils/scrollTo");
const siteContext_1 = require("../../context/siteContext");
var menuItems = [{
        href: "#about",
        label: "About"
    }, {
        href: "#faq",
        label: "FAQ"
    }, {
        href: "#contact",
        label: "Contact"
    }];
var menu = menuItems.reverse();
var MainMenu = function MainMenu() {
    var _useThemeUI = theme_ui_1.useThemeUI(), offsets = _useThemeUI.theme.offsets;
    var _useDispatch = siteContext_1.useDispatch(), dispatch = _useDispatch.dispatch, ACTIONS = _useDispatch.ACTIONS;
    var _useStore = siteContext_1.useStore(), windowHeight = _useStore.windowHeight;
    var _useLocation = router_1.useLocation(), pathname = _useLocation.pathname;
    var scroll = function scroll(id, pathname) {
        dispatch({
            type: ACTIONS.TOGGLE_SIDEBAR,
            payload: {
                state: "CLOSED"
            }
        });
        scrollTo_1.scroll(id, pathname);
    };
    return core_1.jsx(theme_ui_1.Box, {
        sx: {
            position: "fixed",
            width: windowHeight,
            height: offsets.menuWidth,
            borderBottom: "1px solid",
            borderColor: "primary",
            transformOrigin: "left top",
            transform: "rotate(-90deg) translateX(-100%)",
            zIndex: 9,
            bg: "background"
        }
    }, core_1.jsx(theme_ui_1.Flex, {
        css: {
            height: "100%",
            justifyContent: "space-around",
            alignItems: "center"
        }
    }, menu.map(function (item) {
        return core_1.jsx(theme_ui_1.Button, {
            key: item.href,
            variant: "nav",
            onClick: function onClick() {
                return scroll(item.href, pathname);
            }
        }, item.label);
    }), core_1.jsx(theme_ui_1.Button, {
        variant: "smile",
        onClick: function onClick() {
            return scroll("#top", pathname);
        }
    }, core_1.jsx(icons_1.LogoSmile, {
        css: {
            transform: "rotate(90deg)"
        },
        width: "45"
    }))));
};
exports.default = MainMenu;
