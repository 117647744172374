// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-templates-page-index-tsx": () => import("./../../../src/templates/page/index.tsx" /* webpackChunkName: "component---src-templates-page-index-tsx" */),
  "component---src-templates-product-index-tsx": () => import("./../../../src/templates/product/index.tsx" /* webpackChunkName: "component---src-templates-product-index-tsx" */)
}

