"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scroll = void 0;
const gatsby_1 = require("gatsby");
var scroll = function scroll(id, pathname) {
    var hasWindow = typeof window !== "undefined";
    if (!hasWindow)
        return;
    if (pathname !== "/") {
        if (id === "#top")
            return gatsby_1.navigate("/");
        return gatsby_1.navigate("/" + id);
    }
    else {
        var element = document.getElementById(id.replace("#", ""));
        if (element) {
            history.pushState({}, null, "/");
            var offset = function offset(offsetTop) {
                if (id === "#top")
                    return 0;
                return offsetTop - 200;
            };
            window.scrollTo({
                behavior: "smooth",
                top: offset(element.offsetTop)
            });
        }
    }
};
exports.scroll = scroll;
