import React from "react";
import { Wrappers } from "./src/context/siteWrapper";
import { LocationProvider } from "@reach/router";

export const wrapRootElement = ({ element }) => {
  return (
    <LocationProvider>
      <Wrappers>{element}</Wrappers>
    </LocationProvider>
  );
};
